.Background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--context-menu-z-index);
  background-color: rgba(--focus-mode-popout-background-color);
  backdrop-filter: blur(0.5rem);
}

.Modal {
  background-color: var(--context-menu-background-color);
  border: 1px solid var(--context-menu-border-color);
  border-radius: 0.5rem;
  filter: var(--context-menu-filter);
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  font-size: var(--font-size-regular);
  font-family: var(--font-family-default);
  width: 20rem;
}
.Modal[data-confirmation] {
  align-items: center;
  padding: 1rem;
}

.Message {
  font-style: italic;
  word-break: break-word;
}

.Header {
  font-size: var(--font-size-large);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
}

.TextArea {
  height: 8rem;
  resize: none;
  border-radius: 0.25rem;
  border: 1px solid var(--theme-border);
  padding: 0.25rem 0.5rem;
  font-size: var(--font-size-regular);
  background-color: var(--theme-console-input-bgcolor);
}
.TextArea:focus {
  box-shadow: none;
  outline-offset: 0;
  outline: 1px solid var(--primary-accent);
  border: 1px solid var(--primary-accent);
}
.TextArea::placeholder {
  color: var(--color-dimmer);
}

.FormActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1ch;
}

.CloseButton:focus {
  outline-offset: 2px;
  outline: 2px solid var(--primary-accent);
  border-radius: 0.25rem;
}

.CloseButtonIcon {
  width: 1rem;
  height: 1rem;
}

.ScreenshotButton {
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  padding: 0;
}
.ScreenshotButton:focus {
  border-color: transparent;
  box-shadow: none;
  outline-offset: 2px;
  outline: 2px solid var(--primary-accent);
}
.ScreenshotButtonIcon {
  width: 1rem;
  height: 1rem;
  color: var(--primary-accent);
}

.SubmitButton {
  flex: 1 1 auto;
  justify-content: center;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  line-height: 1rem;
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 500;
  background-color: var(--primary-accent);
}
.SubmitButton:hover {
  background-color: var(--primary-accent-hover);
}
.SubmitButton:focus {
  border-color: transparent;
  box-shadow: none;
  outline-offset: 2px;
  outline: 2px solid var(--primary-accent);
}

.HorizontalRule {
  height: 1px;
  border: none;
  border-bottom: 1px solid var(--context-menu-border-color);
}

.Footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1ch;
  padding: 0 0.125rem;
  user-select: none;
}
.Footer[data-confirmation] {
  margin-top: 1rem;
}
.Footer:focus {
  outline-offset: 2px;
  outline: 2px solid var(--primary-accent);
  border-radius: 2px;
}

.FooterIcon {
  fill: currentColor;
}

.FooterButton {
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 500;
}
.FooterButton[color="blue"] {
  border: 1px solid var(--primary-accent);
}
.FooterButton[color="pink"] {
  background-color: var(--secondary-accent);
}
.FooterButton:focus {
  border-color: transparent;
  box-shadow: none;
  outline-offset: 2px;
  outline: 2px solid var(--primary-accent);
}

.ConfirmationMessage {
  margin-bottom: 1rem;
}
