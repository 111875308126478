.timeline-tooltip {
  font-size: 12px;
  display: flex;
  position: absolute;
  bottom: 24px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  z-index: var(--z-index-1--tooltip);
  background: var(--body-bgcolor);
  padding: 3px 5px;
  border-radius: 4px;
  border: 1px solid var(--theme-toggle-bgcolor);
  transform: translateX(-50%);
  pointer-events: none;
  user-select: none;
  white-space: nowrap;
}
